import React, { useState } from "react";
import ButtonAppBar from "../common/components/MaterialUI/components/AppBar";
import SignIn from "../common/components/MaterialUI/components/SignIn";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { setLanguageSlice } from "../redux/languageSlice";
import { useDispatch } from "react-redux";
import finnish from "../languages/finnish.json";
import english from "../languages/english.json";
import eakrlogo from "../static/eakrlogo.png";
import seamkLogo from "../static/seamkLogo.png";
import vipuvoimaaLogo from "../static/vipuvoimaaLogo.png";
import epLiittoLogo from "../static/epLiittoLogo.jpg";
import euLogo2 from "../static/euLogo2.jpg";
import DigiNet_pink from "../static/DigiNet_pink.png";
import vaasaLogo from "../static/vaasaLogo.jpg";
import EPLiittoLogo2 from "../static/EPLiitto_logo_vaaka_vari.jpg";

const Login = () => {
  const [gdprAccepted, setGdprAccepted] = useState(
    localStorage.getItem("gdprAccepted") === "true" ? true : false
  );
  const [open, setOpen] = useState(
    localStorage.getItem("gdprAccepted") === "true" ? false : true
  );
  const [language, setLanguage] = React.useState();

  const dispatch = useDispatch();

  const reduxLangauge = useSelector((state) => state.language.value.language);

  let useLanguage =
    reduxLangauge === "finnish"
      ? finnish
      : localStorage.getItem("language") === "english"
      ? english
      : english;

  const handleClose = () => {
    setOpen(false);
  };

  const accept = () => {
    setGdprAccepted(true);
    localStorage.setItem("gdprAccepted", true);
    setOpen(false);
  };

  const decline = () => {
    setGdprAccepted(false);
    setOpen(false);
  };

  const handleChange = (event) => {
    setLanguage(event.target.value);
    dispatch(setLanguageSlice(event.target.value));
    localStorage.setItem("language", event.target.value);
  };

  const handleForgotPassword = () => {

  }

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const surveyCode = params.get("code");
  localStorage.setItem("surveyCode", surveyCode)
  //console.log("surveyCode: ", surveyCode);
 

  return (
    <div>
      <ButtonAppBar />

      <Dialog
        onClose={handleClose}
        open={localStorage.getItem("gdprAccepted") === "true" ? false : open}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle>GDPR</DialogTitle>
          <FormControl sx={{ m: 1, minWidth: 120 }} color="white">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                language ? language : reduxLangauge ? reduxLangauge : "finnish"
              }
              label="language"
              onChange={handleChange}
            >
              <MenuItem value={"finnish"}>Finnish</MenuItem>
              <MenuItem value={"english"}>English</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{ margin: "5%" }}>
          {/*<h2>{useLanguage.GDPR.title}</h2>*/}
          <h3>{useLanguage.GDPR.text1}</h3>
          {useLanguage.GDPR.text2}
          {useLanguage.GDPR.text3}
          <a href="https://projektit.seamk.fi/yrittajyys-ja-kasvu/diginet/digitalisoituvat-yritysverkostot-tietosuojaseloste/">https://projektit.seamk.fi/yrittajyys-ja-kasvu/diginet/digitalisoituvat-yritysverkostot-tietosuojaseloste/</a>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              style={{ margin: "5%", padding: "2%" }}
              onClick={decline}
            >
              {useLanguage.GDPR.decline}
            </Button>
            <Button
              variant="contained"
              color="success"
              style={{ margin: "5%", padding: "2%" }}
              onClick={accept}
            >
              {useLanguage.GDPR.accept}
            </Button>
          </div>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", flexWrap: "wrap", marginTop: "15%"}}>
            {/*<div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-around", width: "25vh", margin: "2%", alignItems: "center"}}>*/}
            <img src={euLogo2} alt="Euroopan unionin osarahoittama logo" style={{height: "8vh"}}  />
            <img src={DigiNet_pink} alt="Diginetin logo" style={{height: "4vh"}}  />
            {/*</div>
            <div style={{display: "flex", justifyContent: "space-around", width: "25vh", margin: "2%", alignItems: "center"}}>*/}
            <img src={seamkLogo} alt="seamk logo" style={{height: "5vh"}}  />
            <img src={EPLiittoLogo2} alt="EP liitto logo" style={{height: "5vh"}}  />
            <img src={vaasaLogo} alt="vaasan yliopisto logo" style={{height: "8vh"}} />
            {/*</div>*/}
          </div>

        </div>
      </Dialog>
      {gdprAccepted && open === false ? (
        <div>
           
            <SignIn  useLanguage={useLanguage.SignIn} surveyCode={surveyCode} />
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", flexWrap: "wrap", marginTop: "15%"}}>
            {/*<div style={{display: "flex", justifyContent: "space-around", alignItems: "center", width: "40%", margin: "2%", flexWrap: "wrap"}}>*/}
            <img src={euLogo2} alt="Euroopan unionin osarahoittama logo" style={{height: "8vh"}}  />
            <img src={DigiNet_pink} alt="Diginetin logo" style={{height: "4vh"}}  />
            {/*</div>
            <div style={{display: "flex", justifyContent: "space-around", width: "40%", margin: "2%", alignItems: "center", flexWrap: "wrap"}}>*/}
            <img src={seamkLogo} alt="seamk logo" style={{height: "5vh"}}  />
            <img src={EPLiittoLogo2} alt="EP liitto logo" style={{height: "5vh"}}  />
            <img src={vaasaLogo} alt="vaasan yliopisto logo" style={{height: "8vh"}} />
            {/*</div>*/}
        </div>
           
        </div>
        
      ) : open === true ? null : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h1 style={{ marginTop: "20%" }}>{useLanguage.GDPR.sorry}</h1>
        </div>
      )}
    </div>
  );
};

export default Login;
