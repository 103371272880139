import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import configData from '../../../../config.json'
// import { useState } from 'react'

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://seamk.fi/">
        SeAMK
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn(props) {
  //const [loading, setLoading] = useState(true)
  const [loginError, setLoginError] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [forgotPasswordContinued, setForgotPasswordContinued] = useState(false);
  //console.log("propsia: ", props)
  const { surveyCode } = props;
 
  const navigate = useNavigate();
  //    const [completed, setCompleted] = useState(false)
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const username = data.get("username");
    const password = data.get("password");
    //const res = await axios.get('http://localhost:8080/get/user/login/', { params: { username, password } })
    //console.log(`Yrittää tähän osoitteeseen: ${configData.SERVER_URL}/get/user/login/`)
    //console.log("parametrit; username: ", username, "password: ", password)
    const fetchData = async () => {
      try {
        const res = await axios.get(`${configData.SERVER_URL}/get/user/login/`, {
          params: { username, password },
        });
        //const res = await axios.get('http://172.20.10.3:8080/user/login/', { params: { username, password } })
        //console.log("response is...", res.data.length);
        if (Object.keys(res.data).length > 0 && Object.keys(res.data.data).includes("token")) {
          //console.log("login response: ", res.data.data)
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem("authenticated", true);
          localStorage.setItem("username", username);
          localStorage.setItem("role", res.data.data.role);
          navigate("/");
        } else {
          setLoginError(true);
          window.alert("Wrong email or password")
          localStorage.setItem("authenticated", false);
        }
      } catch (error) {
        console.error("epäonnistui, fetch error: ", error);
        //setFetchError(error)
      }
      //setLoading(false)
    };

    fetchData();
  };

  const handleForgotPasswordSubmit = async (event) => {
    event.preventDefault();
    setForgotPasswordContinued(true);
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const email = data.get("email");
    //const res = await axios.get('http://localhost:8080/get/user/login/', { params: { username, password } })
    //console.log(`Yrittää tähän osoitteeseen: ${configData.SERVER_URL}/get/user/login/`)
    //console.log("parametrit; username: ", username, "password: ", password)
    const fetchData = async () => {
      try {
        const res = await axios.get(`${configData.SERVER_URL}/get/user/password/renewal/`, {
          params: { email },
        });
        //const res = await axios.get('http://172.20.10.3:8080/user/login/', { params: { username, password } })
        //console.log("response is...", res.data.length);
        
      } catch (error) {
        console.error("epäonnistui, fetch error: ", error);
        //setFetchError(error)
      }
      //setLoading(false)
      
    };

    fetchData();
  };

  const handleForgotPassword = () => {

  }

  if(surveyCode !== "" && surveyCode !== null){
    //console.log("surveyCode oli: ", surveyCode)
    const fetchData = async () => {
      //const username = "entreuser@seamk.fi"
      const username = configData.PUBLIC_USER
      //const password = "user"
      const password = configData.PUBLIC_USER_PASSWORD
      try {
        const res = await axios.get(`${configData.SERVER_URL}/get/user/login/`, {
          params: { username, password },
        });
        //const res = await axios.get('http://172.20.10.3:8080/user/login/', { params: { username, password } })
        //console.log("response is...", res.data.length);
        if (Object.keys(res.data).length > 0 && Object.keys(res.data.data).includes("token")) {
          //console.log("login response: ", res.data.data)
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem("authenticated", true);
          localStorage.setItem("username", username);
          localStorage.setItem("role", res.data.data.role);
          navigate("/");
        } else {
          setLoginError(true);
          window.alert("Wrong email or password")
          localStorage.setItem("authenticated", false);
        }
      } catch (error) {
        console.error("epäonnistui, fetch error: ", error);
        //setFetchError(error)
      }
      //setLoading(false)
    };

    fetchData();
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
      
        <CssBaseline />
        {
              forgotPassword ? 
              <div>
                {
                  forgotPasswordContinued ? 
                  <div>
                     <Box
                  sx={{
                    marginTop: "50%",
                    marginBottom: "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                    {props.useLanguage.forgotPasswordInfo}
                </Box>
                   
                  </div>
                  :
                  <Box
                  sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    {props.useLanguage.forgotPasswordTitle}
                  </Typography>
                  <Typography component="h3" variant="h7" style={{textAlign: "center", marginTop: "2%"}}>
                    {props.useLanguage.forgotPasswordSubTitle}
                  </Typography>
                  <Box
                    component="form"
                    onSubmit={handleForgotPasswordSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                  >
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label={props.useLanguage.username}
                      name="email"
                      autoComplete="email"
                      autoFocus
                    />
                    {loginError && (
                      <p style={{ color: "red" }}>{props.loginError}</p>
                    )}
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      {props.useLanguage.forgotPasswordSubmit}
                    </Button>
                    <Grid container>
                      
                      <Grid item>
                        <Link component="button" onClick={() => navigate("/register")} variant="body2">
                            {props.useLanguage.register}
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                }
               
              </div>
              :
              <div>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {props.useLanguage.title}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            {/*<TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email address"
              name="email"
              autoComplete="email"
              autoFocus
            />*/}
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label={props.useLanguage.username}
              name="username"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={props.useLanguage.password}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {loginError && (
              <p style={{ color: "red" }}>{props.loginError}</p>
            )}
            {/*<FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={props.useLanguage.remember}
            />*/}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {props.useLanguage.submit}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" onClick={() => setForgotPassword(true)}>
                {props.useLanguage.forgotPassword}
                </Link>
              </Grid>
              <Grid item>
                <Link component="button" onClick={() => navigate("/register")} variant="body2">
                    {props.useLanguage.register}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        </div>}
        <Copyright sx={{ mt: 8, mb: 4 }} />
       
      </Container>
    </ThemeProvider>
  );
}
